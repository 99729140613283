module.exports = {
  content: [
    'cms/**/*.vue',
    'components/**/*.vue',
    'helpers/**'
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1200px',
      '2xl': '1440px',
      '3xl': '1600px',
      '4xl': '1920px'
    },
    colors: {
      /*
        This color list is aligned with the design team.
        Before modifying it, please align with them and establish a common name and value.
      */
      transparent: 'unset',
      EXPONDO: {
        black: '#1A1A1A',
        white: '#FFFFFF',
        primary: {
          DEFAULT: '#00175A',
          80: '#304B9A',
          60: '#6076B4',
          40: '#88A3CB',
          20: '#B9C8E4',
          10: '#DFE7F6',
          5: '#F2F3F7'
        },
        secondary: {
          DEFAULT: '#1594DD',
          light: '#E7F5FF',
          medium: '#7ACAFF'
        },
        gold: {
          DEFAULT: '#FF9C00',
          dark: '#EC960F'
        },
        green: {
          DEFAULT: '#16A34A',
          accent: '#C7E356',
          light: '#D7F7D5',
          medium: '#8BD055'
        },
        grey: {
          DEFAULT: '#3E3E40',
          60: '#797B81',
          20: '#C5C7C9',
          10: '#EBEBEB',
          5: '#F6F6F6'
        },
        orange: '#FF5C19',
        red: {
          DEFAULT: '#F30000',
          dark: '#B30000',
          light: '#FFE6E6'
        },
        yellow: {
          DEFAULT: '#FACF00',
          light: '#FFF6D4'
        }
      }
    },
    borderColor: theme => ({
      ...theme('colors'),
      DEFAULT: theme('colors.EXPONDO.grey.20')
    }),
    extend: {
      boxShadow: {
        thin: '0 -1px 4px 0 #0000001a'
      },
      lineHeight: {
        4.5: '1.125rem',
        11: '3.125rem',
        13: '4.375rem'
      },
      width: {
        fit: 'fit-content',
        0.75: '0.1875rem',
        7.5: '1.875rem',
        11.5: '2.8125rem',
        22: '5.5rem'
      },
      height: {
        fit: 'fit-content',
        0.75: '0.1875rem',
        5.5: '1.375rem',
        6.5: '1.625rem',
        7.5: '1.875rem',
        11.5: '2.8125rem',
        22: '5.5rem'
      },
      maxWidth: {
        demandValidation: '14rem',
        relatedProducts: 'calc(100% - 15rem)'
      },
      minHeight: {
        unset: 'unset',
        12: '3rem',
        22: '5.5rem',
        64: '16rem',
        160: '40rem',
        192: '45rem'
      },
      inset: {
        carouselControl: 'calc(50% - 1.5rem)'
      },
      fontSize: {
        eyeIcon: '0.625rem',
        closeIcon: '0.5rem',
        xxs: '0.625rem'
      },
      padding: {
        0.25: '0.0625rem',
        1.25: '0.3125rem',
        22: '5.5rem'
      },
      fontFamily: {
        'open-sans': ['Open Sans', 'sans-serif']
      }
    }
  }
};
